<template>
  <v-layout column>
    <FieldHeader
      v-if="!hideHeader"
      :field="field"
      :hideCollapseBtn="hideCollapseBtn"
      :collapsed="hideBody"
      :owner="owner"
      :name="name"
      @history="onHistory"
      @collapse="onCollapse"
    ></FieldHeader>
    <v-layout v-if="!disabled && !hideBody" wrap>
      <NewDrawingButton
        class="mr-3 mb-3"
        :dense="fieldData.length == 0"
        :loading="loading"
        :name="name"
        @new="onEdit"
      ></NewDrawingButton>
      <DrawingPreviewButton
        class="mr-3 mb-3"
        v-for="drawing in fieldData"
        :key="drawing.id"
        :value="drawing"
        :session="session"
        :patient="patient"
        :owner="owner"
        :name="name"
        @edit="onEdit"
        @remove="onRemove"
        deletable
      ></DrawingPreviewButton>
    </v-layout>
    <DisabledFieldWrapper
      v-else-if="!hideBody"
      :field="field"
      :session="session"
      :owner="owner"
      hideEdit
    >
      <v-layout wrap class="my-3">
        <NewDrawingButton
          class="mr-3 mb-3"
          v-if="!owner"
          :dense="fieldData.length == 0"
          :loading="loading"
          :name="name"
          :owner="owner"
          @new="onShowEditDialog"
        ></NewDrawingButton>
        <DrawingPreviewButton
          class="mr-3 mb-3"
          v-for="drawing in fieldData"
          :key="drawing.id"
          :value="drawing"
          :session="session"
          :patient="patient"
          :owner="owner"
          :name="name"
          @edit="onShowEditDialog"
        ></DrawingPreviewButton>
      </v-layout>
    </DisabledFieldWrapper>
    <EditFieldDialog
      v-if="showEditDialog"
      :value.sync="showEditDialog"
      :field.sync="currentEditField"
      :name="name"
      :session="session"
      @edited="onEdited"
    >
    </EditFieldDialog>
  </v-layout>
</template>

<script>
import ProxyCRUDMixin from "@/mixins/ProxyCRUDMixin";
import PatientService from "@/services/patient.service";

export default {
  mixins: [ProxyCRUDMixin],
  props: {
    field: {},
    session: {},
    patient: {},
    owner: {},
    disabled: {
      type: Boolean,
      default: false,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    hideCollapseBtn: {
      type: Boolean,
      default: false,
    },
    hideBody: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showEditDialog: false,
      currentEditField: null,
    };
  },
  computed: {
    fieldData() {
      if (!this.field.data || !this.field.data.drawings) {
        return [];
      } else {
        return this.field.data.drawings;
      }
    },
  },
  components: {
    FieldHeader: () =>
      import("@/components/delegates/patientcard/fields/FieldHeader"),
    NewDrawingButton: () =>
      import("@/components/buttons/editor/NewDrawingButton"),
    DrawingPreviewButton: () =>
      import("@/components/buttons/editor/DrawingPreviewButton"),
    DisabledFieldWrapper: () =>
      import("@/components/delegates/patientcard/fields/DisabledFieldWrapper"),
    EditFieldDialog: () =>
      import("@/components/popups/patientcard/EditFieldDialog"),
  },
  methods: {
    onShowEditDialog(data) {
      this.currentEditField = JSON.parse(JSON.stringify(this.field));
      this.currentEditField.data = data;

      this.showEditDialog = true;
    },
    onEdit(data) {
      let drawing = JSON.parse(JSON.stringify(this.field));
      drawing.data = data;

      this.beforeRequest();
      PatientService.updateHistoryField(
        this.field.name,
        drawing
      )
        .then((reply) => {
          this.$set(this.field, "data", reply.data);
          this.reset();
        })
        .catch((error) => {
          console.log("ERROR", error, error.response);
          this.reset();
          this.handleError("Coś poszło nie tak.");
        });
    },
    onEdited(reply) {
      this.$set(this.field, "data", reply.data);
      this.$set(this.field, "reason", reply.reason);
      this.$set(this.field, "ip", reply.ip);
      this.$set(this.field, "modified", reply.modified);
      this.$set(this.field, "edited_by", reply.edited_by);
    },
    onRemove(data) {
      let drawing = JSON.parse(JSON.stringify(this.field));
      drawing.data = data;

      this.beforeRequest();
      PatientService.removeDrawingFromHistoryField(
        this.field.name,
        drawing
      )
        .then((reply) => {
          this.onEdited(reply);
          this.reset();
        })
        .catch((error) => {
          console.log("ERROR", error, error.response);
          this.reset();
          this.handleError("Coś poszło nie tak.");
        });
    },
    onHistory() {
      this.$emit("history");
    },
    onCollapse() {
      this.$emit("collapse");
    },
  },
};
</script>